<script setup lang="ts">
const { t } = useT();
const { open } = useTaoModals();
const loginGuard = useLoginGuard();

const isPrizeDropsPage = useState<boolean>("prize-drops-page", () => false);

const handleClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/prize-drops/");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};
</script>

<template>
	<div v-if="!isPrizeDropsPage" class="gift-label flex-center" @click="handleClick">
		<div class="images">
			<NuxtImg
				v-for="(_, i) in 4"
				:key="i"
				:src="`/nuxt-img/prize-drops/ico-${i + 1}.png`"
				:class="`icon icon-${i}`"
				width="48"
				height="48"
				loading="lazy"
				format="avif"
				alt="icon label"
			/>
		</div>
		<AText :size="12" :modifiers="['bold', 'uppercase']">{{ t("prizeDrops.GiftLabel") }}</AText>
	</div>
</template>

<style lang="scss" scoped>
.gift-label {
	width: 90px;
	height: 60px;
	padding: 2px;
	cursor: pointer;
	position: fixed;
	right: 0;
	bottom: 270px;
	background: radial-gradient(108% 86% at 50% 14%, #019bff 22%, #0157ff 45%, #0d2bb0 74%, #160d7a 100%);
	border-radius: 10px 0 0 10px;
	z-index: 14;
	box-shadow: 0 4.5px 4.5px var(--chile);

	@media (max-width: 1000px) and (orientation: landscape) {
		bottom: 100px;
	}

	@include media-breakpoint-down(md) {
		bottom: 130px;
	}

	.images {
		flex-shrink: 0;
		width: 40px;
		height: 40px;
		position: relative;
	}

	.icon {
		position: absolute;
		object-fit: contain;
		z-index: 1;
	}

	.icon-0 {
		width: 12px;
		height: 14px;
		top: 5px;
		left: 0;
		animation: cosmic 8s linear -3s infinite alternate;
	}

	.icon-1 {
		width: 13px;
		height: 13px;
		top: -10px;
		right: 15px;
		animation: cosmic 10s linear -5s infinite alternate;
	}

	.icon-2 {
		width: 13px;
		height: 13px;
		bottom: 5px;
		right: 4px;
		animation: cosmic 8s linear 2s infinite alternate;
	}

	.icon-3 {
		width: 33px;
		height: 33px;
		z-index: 2;
		animation: cosmic 5s linear 1s infinite alternate;
	}

	@keyframes cosmic {
		0% {
			transform: translateX(0) translateY(0) rotate(0deg);
		}
		20% {
			transform: translateX(2px) translateY(2px) rotate(2deg);
		}
		40% {
			transform: translateX(3px) translateY(4px) rotate(4deg);
		}
		60% {
			transform: translateX(2px) translateY(5px) rotate(2deg);
		}
		80% {
			transform: translateX(0px) translateY(3px) rotate(-4deg);
		}
		100% {
			transform: translateX(-3px) translateY(2px) rotate(-2deg);
		}
	}
}
</style>
